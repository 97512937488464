/*
const burgermenuButton = document.getElementById("burgermenu-button");
const burgermenu = document.getElementById("burger-navigation");
const burgermenuCloseButton = document.getElementsByClassName("closeButton")[0];
let isBurgerMenuOpen = !burgermenuButton.classList.contains("closed");
const menuToggle = () => {
  if (isBurgerMenuOpen) {
    isBurgerMenuOpen = false;
    burgermenuButton.classList.add("closed");
    burgermenu.classList.add("closed");
  }
  else {
    isBurgerMenuOpen = true;
    burgermenuButton.classList.remove("closed");
    burgermenu.classList.remove("closed");
  }
};
burgermenuButton.onclick = menuToggle;
burgermenuCloseButton.onclick = menuToggle;
*/

/* Ab hier deprecated */
const navbar = document.getElementById("navbar");
export const menuiconClick = () => {
  if (navbar.classList.contains("responsive")) {
    navbar.classList.remove("responsive");
  }
  else {
    navbar.classList.add("responsive");
  }
};
const dropdownTargets = document.getElementsByClassName("dropdown");
const dropdownTriggers = document.getElementsByClassName("dropbtn");
let navTimer;
/* Introduced to prevent false clicks */
let draggin = false;
for (const dropdownTrigger of dropdownTriggers) {
  const activateDropdown = (event) => {
    event.stopPropagation();
    if (draggin) {
      draggin = false;
      return;
    }
    clearTimeout(navTimer);
    Array.from(dropdownTargets).map((otherDropdownTarget) => {
      const dropdownContent = otherDropdownTarget.querySelector(".dropdown-content");
      if (dropdownContent) {
        /* This is needed so that doubleclicks dont happen in chrome mobile browser */
        setTimeout(() => {
          otherDropdownTarget.querySelector(".dropbtn") !== dropdownTrigger
            ? dropdownContent.style.display = "none"
            : otherDropdownTarget.querySelector(".dropdown-content").style.display = "block";
        }, 100);
      }
    });
  };
  dropdownTrigger.addEventListener("mouseenter", activateDropdown);
  dropdownTrigger.addEventListener("touchmove", () => {
    draggin = true;
  });
  dropdownTrigger.addEventListener("touchend", activateDropdown);
  dropdownTrigger.parentElement.parentElement.addEventListener("mouseleave", (event) => {
    event.stopPropagation();
    clearTimeout(navTimer);
    const dropDownContent =  dropdownTrigger.parentElement.querySelector(".dropdown-content");
    navTimer = setTimeout(() =>  {dropDownContent ? dropDownContent.style.display = "none" : null;}, 1000);
  });
}
