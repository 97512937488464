export const registerEventlisteners = () => {
  const buttons = document.querySelectorAll(".extend");
  for (const button of buttons) {
    const id = button.id.replace("button-", "");
    const hiddenContent = document.getElementById(`paragraph-${id}`);
    // const arrowOpen = document.getElementById(`arrow-open-${id}`);
    // const arrowClose = document.getElementById(`arrow-close-${id}`);
    if (hiddenContent) {
      button.addEventListener("click", () => {
        if (hiddenContent.style.display === "block") {
          hiddenContent.style.display = "none";
          button.className = "extend";
          // arrowOpen.style.display = "block";
          // arrowClose.style.display = "none";
        } else {
          hiddenContent.style.display = "block";
          button.className = "extend active";
          // arrowOpen.style.display = "none";
          // arrowClose.style.display = "block";
        }
      });
    }
  }
};
