import { isMobile, inRange, isOutsideRange, round } from "./util";

export const init = () => {
  const headline = document.getElementById("headline");
  const image = document.getElementById("landing-image-owl");
  const threecolumns = document.getElementById("threecolumns");

  if (!threecolumns || !headline) return;

  if (!isMobile()) {
    headline.style.opacity = isMobile() ? 0 : 1;
    threecolumns.style.opacity = isMobile() ? 0 : 1;
    return;
  }

  if (headline) {
    headline.style.opacity = isMobile() ? 0 : 1;
  }
  if (threecolumns) {
    threecolumns.style.opacity = isMobile() ? 0 : 1;
  }

  // == Opacity Configuration ================================================================================

  // Both elements have range where they are visible.
  // They are adjusted for mobile and desktop so you will have
  // ensure the values work for both versions.
  // The values are based on the window height
  // e.g. if a element should end with '0.95'
  // the element will be invisible when the user has
  // scrolled down 95% of the window height.
  const HEADER_START = 0.0;
  const HEADER_END = isMobile() ? 0.15 : 0.3;
  const THREE_START = isMobile() ? 0.25 : 0.35;
  const THREE_END = isMobile() ? 1 : 0.95;

  // These values describe how the range of an element is split.
  // So if the value is 1/3, the element will increase its
  // opacity from 0 to 1 in the first third of its visiblity range
  // and decrease from 1 to 0 in the last third.
  // In the middle third the opacity is 1.
  // | =  =  =  =  =  =  =  =  =  | (length of '9')
  // | 0 .5  1  1  1  1  1  .5 0  |
  const THREE_COLUMN_FADE_RANGE_FACTOR  = 1 / 3;
  // IMPORTANT: the headline will start at 1 opacity
  // so it will only fade out in the last half. **
  // | =  =  =  =  =  =  =  = | (length of '9')
  // | 1  1  1  1 .9 .6 .3  0 |
  const HEADLINE_FADE_RANGE_FACTOR = 1 / 2;

  // This values describes at what opacity level
  // the 3 columns become clickable
  // For the fading out, the value is 1 - $value.
  const POINTER_EVENT_THRESHOLD = 0.25;

  // This value describes how many steps the fade effect will have.
  const FADE_STEPS = 60;
  // ==========================================================================================================

  // NO-FUN-ZONE DO NOT TOUCH

  const clientHeight = window.innerHeight;


  const scrollTargetHeadline = clientHeight * HEADER_START;
  const scrollTargetEndheadline = clientHeight * HEADER_END;
  const scrollTargetThreecolumns = clientHeight * THREE_START;
  const scrollTargetEndThreecolumns = clientHeight * THREE_END;


  const headlineLength = scrollTargetEndheadline - scrollTargetHeadline;
  const HEADLINE_FADE_RANGE = HEADLINE_FADE_RANGE_FACTOR * headlineLength;

  const headlineFadeInStart = scrollTargetHeadline;
  const headlineFadeInEnd = 0; // **
  const headlineFadeOutStart = Math.floor(scrollTargetEndheadline - HEADLINE_FADE_RANGE);
  const headlineFadeOutEnd = scrollTargetEndheadline;

  const headlineFadeLength = headlineFadeOutEnd - headlineFadeOutStart;

  const threeColumnLength = scrollTargetEndThreecolumns - scrollTargetThreecolumns;
  const THREE_FADE_RANGE = THREE_COLUMN_FADE_RANGE_FACTOR * threeColumnLength;

  const threeColumnFadeInRangeStart = scrollTargetThreecolumns;
  const threeColumnFadeInRangeEnd = Math.floor(scrollTargetThreecolumns + THREE_FADE_RANGE);
  const threeColumnFadeOutRangeStart = Math.floor(scrollTargetEndThreecolumns - THREE_FADE_RANGE);
  const threeColumnFadeOutRangeEnd = scrollTargetEndThreecolumns;

  const threeColumnFadeLength = threeColumnFadeOutRangeEnd - threeColumnFadeOutRangeStart;

  const HEADLINE_FADE_STEP = headlineFadeLength / FADE_STEPS;
  const THREE_FADE_STEP = threeColumnFadeLength / FADE_STEPS;

  const blurFactor = 20;

  let lastScrollPos = window.scrollY;

  const onScroll = () => {
    if (!isMobile()) {
      return;
    }
    if (!threecolumns || !headline) return;
    const currentScrollPos = window.scrollY;

    const diff = Math.abs(currentScrollPos - lastScrollPos);
    if (diff === 0 && currentScrollPos !== lastScrollPos) return;
    lastScrollPos = currentScrollPos;

    // Headline

    if (inRange(currentScrollPos, headlineFadeInStart, headlineFadeInEnd)) {
      const pos = currentScrollPos - headlineFadeInStart;
      const step = round(pos / HEADLINE_FADE_STEP);
      const opa = Math.abs(step / FADE_STEPS);
      headline.style.opacity = opa;
    }
    if (inRange(currentScrollPos, headlineFadeInEnd, headlineFadeOutStart)) {
      headline.style.opacity = 1.0;
    }
    if (inRange(currentScrollPos, headlineFadeOutStart, headlineFadeOutEnd)) {
      const pos = currentScrollPos - headlineFadeOutStart;
      const step = round(pos / HEADLINE_FADE_STEP);
      const opa =  1 - Math.abs(step / FADE_STEPS);
      headline.style.opacity = opa;
    }
    if (isOutsideRange(currentScrollPos, scrollTargetHeadline, scrollTargetEndheadline)) {
      headline.style.opacity = 0;
    }

    // Three Columns

    if (inRange(currentScrollPos, threeColumnFadeInRangeStart, threeColumnFadeInRangeEnd)) {
      const pos = currentScrollPos - threeColumnFadeInRangeStart;
      const step = round(pos / THREE_FADE_STEP);
      const opa =  Math.abs(step / FADE_STEPS);
      threecolumns.style.opacity = opa;
      threecolumns.style.pointerEvents = opa >= POINTER_EVENT_THRESHOLD ? "auto" : "none";
      if (isMobile()) {
        const blur = Math.abs((opa * blurFactor) / 4);
        image.style.filter = `blur(${blur}px)`;
      }
    }
    if (inRange(currentScrollPos, threeColumnFadeInRangeEnd, threeColumnFadeOutRangeStart)) {
      threecolumns.style.opacity = 1;
      threecolumns.style.pointerEvents = "auto";
      if (isMobile()) {
        image.style.filter = "blur(5px)";
      }
    }
    if (inRange(currentScrollPos, threeColumnFadeOutRangeStart, threeColumnFadeOutRangeEnd)) {
      const pos = currentScrollPos - threeColumnFadeOutRangeStart;
      const step = round(pos / THREE_FADE_STEP);
      const opa =  1 - Math.abs(step / FADE_STEPS);
      threecolumns.style.opacity = opa;
      threecolumns.style.pointerEvents = opa <= POINTER_EVENT_THRESHOLD ? "none" : "auto";
      if (isMobile()) {
        const blur = Math.abs((opa * blurFactor) / 4);
        image.style.filter = `blur(${blur}px)`;
      }
    }
    if (isOutsideRange(currentScrollPos, scrollTargetThreecolumns, scrollTargetEndThreecolumns)) {
      threecolumns.style.opacity = 0;
      threecolumns.style.pointerEvents = "none";
      if (isMobile()) {
        image.style.filter = "blur(0px)";
      }
    }
    lastScrollPos = currentScrollPos;
  };
  onScroll();
  window.addEventListener("scroll", onScroll);
};
