const enableDebugLogging = false;

export const logger = (...message) => {
  if (enableDebugLogging) {
    // eslint-disable-next-line no-console
    console.log(...message);
  }
};
export const wait = async(duration) => new Promise((resolve) => setTimeout(resolve, duration));

/**
 * Fetch remote JSON
 * @param {string} url - the url to fetch
 * @returns an array of the json elements
 */
export const request = async(url) => {
  try {
    logger(`util: fetching: ${url}`);
    const response = await window.fetch(url);
    return await response.json();
  } catch (error) {
    logger(error);
    return [];
  }
};
/**
 * Check if the site is being viewed in its english version.
 * @returns true if the site is in english, false otherwise.
 */
export const isEnglishCheck = () => window.location.href.includes("/en/");
/**
 * Generate a random number between 0 and `max`.
 * @param {number} max - the upper threshold of the random number.
 * @returns A random number between 0 and `max`.
 */
export const rng = (max) => Math.floor(Math.random() * max);
/**
 * Check if the site is being viewed on a mobile device.
 * @returns true if the site is in mobile mode, false otherwise.
 */
export const isMobile = () => window.innerWidth <= 992;
/**
 * Check if the site is being viewed on a tablet device.
 * @returns true if the site is in tablet mode, false otherwise.
 */
export const isTablet = () => window.innerWidth >= 768 && window.innerWidth <= 1366;
/**
 * Check if the given values is within the given bounds.
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns true if the value is in range, false otherwise.
 */
export const inRange = (value, min, max) => value >= min && value <= max;
/**
 * Check if the given values is outside the given bounds.
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @returns true if the valie is outside the range, false otherwise.
 */
export const isOutsideRange = (value, min, max) => value < min || value > max;
/**
 * Round the number to 2 decimal places.
 * @param {number} num
 * @returns the nearest number with 2 decimal places.
 */
export const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;
// ==========================================================================
export const disablePointerEvents = (element) => {
  element.style.pointerEvents = "none";
};
export const enablePointerEvents = (element) => {
  element.style.pointerEvents = "auto";
};
export const hide = (element) => {
  element.style.visibility = "hidden";
};
export const unhide = (element) => {
  element.style.visibility = "visible";
};
export const hideAndDisable = (element) => {
  disablePointerEvents(element);
  hide(element);
};
export const unhideAndEnable = (element) => {
  enablePointerEvents(element);
  unhide(element);
};
