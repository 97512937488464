import "../libs/contact/contact";
const getShowWidget = () => {
  if (document.querySelectorAll("#threecolumns").length) {
    return document.documentElement.clientHeight * 0.75;
  }
  else if (document.querySelectorAll(".article").length) {
    return 0;
  }
  else {
    return 0;
  }
};
const getAnimationStart = () => {
  if (document.querySelectorAll(".article").length) {
    const article = document.querySelectorAll(".article")[0].getBoundingClientRect();
    const {
      y
    } = article;
    return y - window.innerHeight / 2;
  }
  else {
    return 0;
  }
};
const shouldAnimate = () => {
  for (const location of ["/blog/", "/news/", "/aktuelle-meldungen/"]) {
    if (window.location.href.includes(location)) {
      return false;
    }
  }
  return true;
};
export const init = () => {
  const animationStart = getAnimationStart();
  const showWidget = getShowWidget();
  const options = {
    data: contactData,
    open: false,
    animation: {
      enable: shouldAnimate(),
      startAt: animationStart
    },
    showAt: showWidget
  };
  initContactForm.default(options, document.getElementById("content"));
};