export const initFlipcards = () => {
  const $flipcards = document.querySelectorAll(".clickFlipcard");
  for (const $flipcard of $flipcards) {
    const clickOnFlipcard = () => {
      $flipcard.querySelector(".box-inner").classList.add("active");
    };
    $flipcard.addEventListener("touchend", () => {
      setTimeout(clickOnFlipcard, 100);
    });
  }
};
