import {
  rng,
  request,
  isEnglishCheck,
  hideAndDisable,
  unhideAndEnable,
  wait,
  isMobile,
  logger,
  isTablet,
  // inRange,
} from "./util";


// When this was created, only me and our lord GabeN knew how this worked.
// Now, only GabeN knows. And he lives in New Zealand.
// Praise GabeN

// Configuration
// =================================================================
const SLIDE_FPS = 60;
// const enableDebugForResize = false;
// =================================================================

const TIME_PER_STEP = Math.floor(1000 / SLIDE_FPS);

const placeholderBasePath = "/alpha/";
const placeholderImages = [
  "burg_sm.png",
  "schachbrett_sm.png",
  "stacheldraht_sm.png",
  "eule_final_sm.png",
  "igel_sm.png",
  "maschine_sm.png"
];

let indexOffset = -1;
let documents = [];
let blogPosts = [];
let blogArchiveRoot = null;
let leftButton = null;
let rightButton = null;
let slideGrid = null;
let blogSlides = [];
let archiveWidth = -1;

// let windowWidth = window.innerWidth;
// let windowHeight = window.innerHeight;

let index = 0;
let isMoving = false;
let isRegistered = false;
let linkPrefix = "";

const setIsMoving = (moving) => {
  isMoving = moving;
};

export const initBlogArchive = async() => {
  if (isRegistered) return;
  logger("Registering Blog Archive");
  logger(`isTablet: ${isTablet()}`);
  logger(`isMobile: ${isMobile()}`);
  indexOffset = isTablet() ? window.innerHeight >= 1366 ? 4 : 2 : isMobile() ? 1 : 8;
  logger(`Using ${indexOffset} for indexOffset`);

  blogArchiveRoot = document.getElementById("blog-archive-root");
  if (!blogArchiveRoot) return;

  leftButton = document.getElementById("blog-archive-button-left");
  rightButton = document.getElementById("blog-archive-button-right");
  if (!blogArchiveRoot || !(leftButton || rightButton)) {
    return;
  }

  if (isEnglishCheck()) {
    linkPrefix = "/en";
  }

  if (documents.length === 0) {
    documents = await request(`${linkPrefix}/blog-search.json`);
  }

  blogPosts = documents.sort((a, b) => b.date - a.date);

  if (leftButton) {
    leftButton.addEventListener("click", onLeftClick);
  }
  if (rightButton) {
    rightButton.addEventListener("click", onRightClick);
  }

  const shownBlogs = document.querySelectorAll(".articles > .summary").length;

  for (var i = shownBlogs; i < blogPosts.length; i += indexOffset) {
    blogSlides.push(blogPosts.slice(i, i + indexOffset));
  }

  blogArchiveRoot.appendChild(createBlogSlides());

  slideGrid = document.getElementById("slide-grid");
  logger(`BlogArchive: ${slideGrid.children.length} blog slides`);

  archiveWidth = slideGrid.scrollWidth / blogSlides.length;
  logger(`BlogArchive Width : ${archiveWidth} ${slideGrid.clientWidth}`);

  renderButtons();
  if (isMobile() || isTablet()) {
    slideGrid.addEventListener("scroll", (e) => {
      if (!isMoving) {
        index = Math.round(e.target.scrollLeft / archiveWidth);
        renderButtons();
      }
    });
  }
  slideGrid.scrollLeft = 0;
  // windowWidth = window.innerWidth;
  // windowHeight = window.innerHeight;
  slideGrid.scrollLeft = index * slideGrid.clientWidth;
  isRegistered = true;
};

export const unregisterBlogArchive = () => {
  if (!isRegistered) return;
  archiveWidth = slideGrid.scrollWidth / blogSlides.length;
  logger(`new archive width: ${archiveWidth}`);
  slideGrid.scrollLeft = index * archiveWidth;
  const newOffset = isTablet() ? window.innerHeight >= 1366 ? 4 : 2 : isMobile() ? 1 : 8;
  if (newOffset === indexOffset) {
    return;
  }
  // const verticalGrow = Math.abs(windowHeight - window.innerHeight);
  // const horizontalGrow = Math.abs(windowWidth - window.innerWidth);
  // if (enableDebugForResize) {
  //   document.getElementById("debug-title").innerHTML = `Vertical: ${verticalGrow} Horizontal: ${horizontalGrow}`;
  // }
  // // nothing to do
  // if (verticalGrow === 0 && horizontalGrow === 0) {
  //   return;
  // }
  // // When scrolling on mobile, the address bar will pop in or out causing resize events.
  // // See here: https://developer.chrome.com/blog/url-bar-resizing/
  // // Probably can't apply that without breaking something else.
  // // On a Samsung Galaxy A52s its 57px, on a Huawei P Smart 2019 its 56px ¯\_(ツ)_/¯ (both Chrome)
  // // 56px seems to also fix it on iPhones, although I can't verify that and don't know
  // // which specific iPhone.
  // // 15/10/2022
  // // On Iphones there's both a vertical and horizontal growth due it having
  // // a bottom and top bar in some browsers. The resize events also seem to
  // // fire differently and the resize growth is very inconsistent.
  // // Technically, we would only have to resize on a horizontal growth but that would
  // // require further testing.
  // if (isMobile() && inRange(verticalGrow, 0, 200) && inRange(horizontalGrow, 0, 200)) {
  //   return;
  // }
  logger("unregistering Blog Archive");
  if (slideGrid) {
    slideGrid.innerHTML = "";
    blogArchiveRoot.removeChild(slideGrid);
  }
  blogArchiveRoot.innerHTML = "";
  leftButton.removeEventListener("click", onLeftClick);
  rightButton.removeEventListener("click", onRightClick);
  indexOffset = -1;
  // documents = [];
  blogPosts = [];
  index = 0;
  blogArchiveRoot = null;
  leftButton = null;
  rightButton = null;
  slideGrid = null;
  blogSlides = [];
  archiveWidth = -1;
  isRegistered = false;
};


const renderButtons = () => {
  showLeftButton(index > 0);
  showRightButton(index < blogSlides.length - 1);
};

const showLeftButton = (show) => {
  if (!show) {
    hideAndDisable(leftButton);
  } else {
    unhideAndEnable(leftButton);
  }
};

const showRightButton = (show) => {
  if (show) {
    unhideAndEnable(rightButton);
  } else {
    hideAndDisable(rightButton);
  }
};

const slideLeft = async() => {
  logger("BlogArchive: click left");
  // archiveWidth = slideGrid.clientWidth;
  setIsMoving(true);
  const start = slideGrid.scrollLeft;
  const end = start - archiveWidth;
  let pos = start;
  const step = Math.round(-(end - start) / TIME_PER_STEP);
  while (pos >= end) {
    await wait(TIME_PER_STEP);
    pos -= step;
    slideGrid.scrollLeft = pos;
  }
  setIsMoving(false);
};

const onLeftClick = () => {
  if (isMoving) return;
  slideLeft()
    .then(() => {
      index = index === 0 ? 0 : index - 1;
      logger(`BlogArchive: index=${index}`);
      if (!isMobile()) {
        slideGrid.scrollLeft = index * archiveWidth;
      }
    })
    .then(renderButtons)
    .catch(logger);
};

const slideRight = async() => {
  logger("BlogArchive: click right");
  // archiveWidth = slideGrid.clientWidth;
  setIsMoving(true);
  const start = slideGrid.scrollLeft;
  const end = start + archiveWidth;
  const step = Math.round((end - start) / TIME_PER_STEP);
  let pos = start;
  while (pos <= end) {
    await wait(TIME_PER_STEP);
    pos += step;
    slideGrid.scrollLeft = pos;
  }
  setIsMoving(false);
};

const onRightClick = () => {
  if (isMoving) return;
  slideRight()
    .then(() => {
      index = index === blogSlides.length ? index : index + 1;
      logger(`BlogArchive: index=${index}`);
      if (!isMobile()) {
        slideGrid.scrollLeft = index * archiveWidth;
      }
    })
    .then(renderButtons)
    .catch(logger);
};

const createArchiveEntry = (blog) => {
  let thumb = "";
  const imageClasses = ["imagecontainer"];
  if (blog.resources) {
    const thumbRes = blog.resources.find((b) => b.name === "thumbnail");
    const titleRes = blog.resources.find((b) => b.name === "title");
    if (thumbRes || titleRes) {
      thumb = `${blog.ref}${thumbRes ? thumbRes.src : titleRes.src}`;
    }
  } else {
    imageClasses.push("placeholder");
    thumb = `${placeholderBasePath}${placeholderImages[rng(placeholderImages.length)]}`;
  }
  let dateStr = "";
  if (blog.date) {
    const date = new Date(Date.parse(blog.date));
    dateStr = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }

  const tagElements = blog.tags
    ? blog.tags.map((t) => `<a href="${linkPrefix}/blog/tags/${t.replaceAll(" ", "-")}" class="label-background"><span class="label-label">#${t}</a>`)
    : [];
  if (tagElements.length > 0) {
    dateStr += " – ";
  }
  const root = document.createElement("article");
  root.classList.add("summary-archive");
  root.classList.add(isTablet() ? "twoBytwo" : "fourByfour");
  root.id = blog.ref;
  root.innerHTML = `
  <a href="${blog.ref}">
    <figure id="${blog.ref}-thumb" class="${imageClasses.join(" ")}"></figure>
  </a>
  <div class="description">
    <a class="title-link" href="${blog.ref}">
        <h2 class="summaryTitle">${blog.title}</h2>
    </a>
    <p class="tags-and-date">
      ${dateStr}
      ${tagElements.length > 0 ? `${tagElements.slice(0, 2).join("")}` : ""}
    </p>
  </div>
  `;
  const figure = root.querySelector("a>figure");
  figure.appendChild(preloadImage(thumb, blog.title));
  return root;
};


const createBlogSlides = () => {
  const root = document.createElement("div");
  root.id = "slide-grid";
  blogSlides.forEach((blogSlide) => {
    const slideContainer = document.createElement("div");
    slideContainer.classList.add("slide-container");
    const slide = document.createElement("div");
    slide.classList.add("slide");
    blogSlide.forEach((blog) => {
      slide.appendChild(createArchiveEntry(blog));
    });
    slideContainer.appendChild(slide);
    root.appendChild(slideContainer);
  });
  return root;
};

const preloadImage = (url, title) => {
  const img = new Image();
  img.src = url;
  img.alt = `${title} thumbnail`;
  img.onerror = `this.onerror=null;this.src="${placeholderBasePath}${placeholderImages[rng(placeholderImages.length)]}";`;
  return img;
};
