import { logger } from "./util";

export const replaceBlogURLS = () => {
  const arr = Array.from(document.querySelectorAll(".blog a"))
    .filter((e) => e.classList.length === 0);
  logger(`found ${arr.length} blog-urls to replace`);
  arr.forEach((ele) => {
    let classNames = "link-background inline";
    const url = ele.href;
    const txt = ele.innerHTML;
    let labels = [];
    if (!txt.includes(" ")) {
      classNames += " no-spaces";
      labels = txt.split("/");
    } else {
      labels = txt.split(" ");
    }

    if (labels.length > 1) {
      classNames += " split";
    }

    const linkWrapper = document.createElement("span");
    linkWrapper.classList.add("markdown-link-wrapper");
    const inlineLink = document.createElement("span");
    inlineLink.classList.add("inline-link");
    linkWrapper.appendChild(inlineLink);

    const isExternal = !url.startsWith(window.location.origin);

    labels.forEach((l, i) => {
      let label = l;
      if (!txt.includes(" ")) {
        if (i !== labels.length - 1) {
          label = `${label}/`;
        }
      }
      [0, 40, 80, 120].forEach((t) => {
        if (label.length > t) {
          const a = document.createElement("a");
          a.href = url;
          a.className = classNames;
          a.classList.add("generated");
          if (isExternal) {
            a.target = "_blank";
            a.rel = "nofollow noreferrer noopener";
          }
          const span = document.createElement("span");
          span.classList.add("link-label");
          span.innerHTML = label.substring(t, t + 40);
          a.appendChild(span);
          inlineLink.appendChild(a);
        }
      });
    });
    const parent = ele.parentElement;
    parent.replaceChild(linkWrapper, ele);
    // parent.removeChild(ele);
    // parent.appendChild(linkWrapper);
  });
};
