import Fuse from "fuse.js";

// SEARCH CONFIGURATION START

/**
 * Items with no title and landing pages (/ and /en/) are filtered from results.
 */


/**
 * The amount of time in ms to debounce a search query after a key-input.
 */
const searchDelay = 250;

/**
 * @type {Fuse.IFuseOptions}
 * https://fusejs.io/api/options.html
*/
const searchOptions = {
  isCaseSensitive: false,
  shouldSort: true,
  findAllMatches: false,
  ignoreLocation: true,
  minMatchCharLength: 2,
  threshold: 0.175,
  includeScore: false,
  /**
   * The index used for the search can be found in 'dist/index.json'.
   * The index gets generated via site/layouts/index.json.json.
   */
  keys: [
    {name: "title", weight: 2},
    {name: "summary", weight: 1.5},
    {name: "searchPreview", weight: 1.0},
    {name: "body", weight: 1.0},
    "tags",
    "author"
  ]
};

const translations = {
  tagsDescriptions: {
    en : "Blog posts about",
    de: "Blogartikel über",
  },
  yearsDescriptions: {
    en: "Blog posts from the year",
    de: "Blogartikel aus dem Jahr"
  },
  noResults: {
    en: "No search results found",
    de: "Keine Suchergebnisse gefunden",
  },
  from: {
    en: "by",
    de: "von"
  }
};

/**
 * Here you can specify redirects for search items.
 * E.g. if a URL for a search results starts with one of the given refs,
 * the search will result direct to ref instead to the item.
 * This way items that are not meant to have a single page (e.g. not reachable by normal navigation)
 * can still appear in the search.
 *
 * /en/certification/tisax => /en/certification
 */
const redirects = {
  en: [
    "/en/certification/"
  ],
  de: [
    "/zertifizierung/"
  ]
};

// SEARCH CONFIGURATION END

let fuse = null;
const $searchInput = document.getElementById("search-input");
const $searchContentContainer = document.getElementById("search-content-container");
const $searchResults = document.getElementById("search-results");
const $searchResultsMobile = document.getElementById("search-results-mobile");
const $searchIcon = document.getElementById("search-icon-glass");
const $clearSearchIcon = document.getElementById("clear-search-icon-container");
const $searchSpinner = document.getElementById("search-loading-spinner");
let previousResults = [];

let isMobile = false;


const showClearIcon = () => {
  $clearSearchIcon.style.display = "block";
  $searchIcon.style.display = "none";
};

const hideClearIcon = () => {
  $clearSearchIcon.style.display = "none";
  $searchIcon.style.display = "block";
};

const mobileCheck = () => {
  const desktop = window.getComputedStyle($searchResults).display;
  isMobile = desktop === "none";
};

const isEnglishCheck = () => window.location.href.includes("/en/");

export const init = async() => {
  const isEnglish = isEnglishCheck();
  const url = `${isEnglish ? "/en/" : "/"}search.json`;
  const documents = await request(url);
  fuse = new Fuse(documents, searchOptions);
  $clearSearchIcon.style.display = "none";
  $clearSearchIcon.addEventListener("click", () => {
    $searchResults.innerHTML = "";
    $searchResultsMobile.innerHTML = "";
    previousResults = [];
    $searchInput.value = "";
    hideClearIcon();
  });

  // Register handler for the search input field
  registerSearchHandler();

  setFocusOnMouseover();
  mobileCheck();
  window.addEventListener("resize", () => {
    mobileCheck();
    if (previousResults.length > 0) {
      renderSearchResults(previousResults);
    }
  });
};

const setFocusOnMouseover = () => {
  const dropDownSearchElement = document.getElementsByClassName("dropdown-search");
  if (dropDownSearchElement && dropDownSearchElement.length) {
    dropDownSearchElement.addEventListener("mouseover", () => {
      $searchInput.focus();
    });
  }
};

async function request(url) {
  try {
    const response = await window.fetch(url);
    return await response.json();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return [];
  }
}

function registerSearchHandler() {
  let timer = null;
  if ($searchInput) {
    $searchInput.oninput = (event) => {
      $searchContentContainer.style.minHeight = "4rem";
      $searchContentContainer.style.display = "table";
      $searchSpinner.style.display = "flex";
      if (timer) {clearTimeout(timer);}
      // account for slower devices
      // search usually takes less than 50ms on a modern desktop
      timer = setTimeout(() => {
        handler(event);
      }, searchDelay);
    };
    $searchInput.onmouseleave = () => {
      $searchContentContainer.style.minHeight = "unset";
      $searchContentContainer.style.display = "block";
    };
  }

  function handler(event) {
    const query = event.target.value;
    const results = fuse.search(query);
    $searchSpinner.style.display = "none";
    $searchContentContainer.style.minHeight = "unset";
    $searchContentContainer.style.display = "block";
    previousResults = results;
    renderSearchResults(results);

    // Remove search results if the user empties the search phrase input field
    if ($searchInput.value === "") {
      $searchResults.innerHTML = "";
      $searchResultsMobile.innerHTML = "";
      previousResults = [];
      hideClearIcon();
    } else {
      showClearIcon();
    }
  }
}

function renderSearchResults(results) {
  const langKey = isEnglishCheck() ? "en" : "de";
  // Create a list of results
  const ul = document.createElement("ul");
  // keept track of elements that need MathJax post-processing
  const mathIds = [];
  if (results.length > 0) {
    results.forEach((result) => {
      // Create result item
      const li = document.createElement("li");
      const { item } = result;
      const { ref } = item;

      // exclude pages w/ no title and the landing page
      if (item.title === "" || ref === "/" || ref === "/en/") {
        return;
      }
      // remove leading '/en/' or '/'
      let type = decodeURI(ref.replace(/^\/(en\/)?/g, ""));

      if (type.startsWith("blog/tags")) {
        type = "tags";
      } else if (type.startsWith("blog/year")) {
        type = "year";
      } else if (type.includes("/")) {
        type = type.split("/")[0];
      }

      const sanitizedType = type
        .replaceAll("-", " ")
        .replaceAll("ae", "ä")
        .replaceAll("oe", "ö")
        .replaceAll("ue", "ü");

      let resultType =  sanitizedType[0].toUpperCase() + sanitizedType.slice(1);

      if (resultType === "Aktülle meldungen") {
        resultType = "Aktuelle Meldungen";
      }
      if (resultType === "Anfahrt und parken") {
        resultType = "Anfahrt und Parken";
      }
      if (resultType === "Freie stellen") {
        resultType = "Freie Stellen";
      }

      let blogAuthorText = "";
      if (resultType === "Blog" && item.author !== null) {
        const from = translations.from[langKey];
        blogAuthorText = `(${from} ${item.author})`;
      }

      const resultTitle = item.title;
      let resultBody = "";
      if (resultType === "Tags") {
        resultBody = `${translations.tagsDescriptions[langKey]} ${resultTitle}`;
      } else if (resultType === "Year") {
        resultBody = `${translations.yearsDescriptions[langKey]} ${resultTitle}`;
      } else {
        if (item.searchPreview !== null) {
          resultBody = item.searchPreview;
        } else if (item.metadescription !== null && item.summary === null) {
          resultBody = item.metadescription;
        } else {
          resultBody = item.summary === null
            ? `${item.body.substring(0, 150).trim()}...`
            : (
              item.summary.length > 150
                ? `${item.summary.substring(0, 150).trim()}...`
                : item.summary
            );
        }
      }

      const descriptionId = `${item.title.replace(/\s/g, "_").replace(":", "_").toLowerCase()}_search_description`;
      if (resultBody.includes("$") || resultBody.includes("$$")) {
        mathIds.push(descriptionId);
      }

      let adjustedHref = ref;

      const redirectsForLang = redirects[langKey];

      for (const r of redirectsForLang) {
        if (adjustedHref.startsWith(r)) {
          adjustedHref = r;
          break;
        }
      }

      if (isMobile) {
        li.innerHTML = `
            <a href="${adjustedHref}">
              <span class="search-result-mobile">
                ${resultType !== "" ? `<p class="search-result-type-mobile">${resultType}</p>` : ""}
                <p class="search-result-title-mobile">${resultTitle}</p>
              </span>
            </a>
          `;
      } else {
        li.innerHTML = `
          <a href="${adjustedHref}">
            <span class="search-result-desktop">
              ${resultType !== "" ? `<p class="search-result-type-desktop">${resultType} ${blogAuthorText}</p>` : ""}
              <p class="search-result-title-desktop">${resultTitle}</p>
              <span id="${descriptionId}" class="search-desc">${resultBody}</span>
            </span>
          </a>
        `;
      }
      ul.appendChild(li);
    });

    // clear existing results
    $searchResults.innerHTML = "";
    $searchResultsMobile.innerHTML = "";
  } else {
    if (isMobile) {
      $searchResultsMobile.innerHTML = `<ul><li><h6 class="noResult">${translations.noResults[langKey]}</h6></li></ul>`;
    } else {
      $searchResults.innerHTML = `<ul><li><h6>${translations.noResults[langKey]}</h6></li></ul>`;
    }
  }

  // Render the list
  if (isMobile) {
    ul.classList.add("scrollGradient");
    $searchResultsMobile.appendChild(ul);
  } else {
    ul.classList.add("scrollGradient");
    $searchResults.appendChild(ul);
  }

  /* global MathJax */
  if (window.location.href.includes("/blog")) {
    mathIds.forEach((id) => {
      MathJax.startup.document.state(0);
      MathJax.texReset();
      MathJax.typeset();
      MathJax.typeset(id);
    });
  }
}
