import "./libs/srcset-polyfill.js";
import {dom, library} from "@fortawesome/fontawesome-svg-core";
import {faLinkedin, faLinkedinIn, faTwitter, faXing} from "@fortawesome/free-brands-svg-icons";
import {init as initLandingPageScroll} from "./js/landingpage";
import {initFlipcards} from "./js/flipcards";
// import {initLinks} from "./js/links";
import {menuiconClick} from "./js/navigation";
import {registerEventlisteners as registerReadmoreEventlisteners} from "./js/readmore";
import {init as initSearch} from "./js/search";
import {init as contactInit} from "./js/contact";
import {initBlogArchive, unregisterBlogArchive} from "./js/blogarchive";
// import {registerSlider, unregisterSlider} from "./js/slider";
import smoothscroll from "smoothscroll-polyfill";
import "./css/main.scss";
import {replaceBlogURLS} from "./js/blogUrls";

// ==================================================================
const configuration = {
  // replace normal URLS (e.g. not [title](url)) with properly styled ones.
  replaceBlogURLS: true
};
// ==================================================================


smoothscroll.polyfill();
window.menuiconClick = menuiconClick;

library.add(faXing, faTwitter, faLinkedin, faLinkedinIn);
dom.watch();

const pathname = window.location.pathname;
const isLandingPage = ["/en/", "/"].includes(pathname) || pathname === "";
const isBlogPage =  pathname.startsWith("/blog") || pathname.startsWith("/en/blog");

initLandingPageScroll();
if (isBlogPage) {
  replaceBlogURLS();
  (async() => {
    await initBlogArchive();
  })();
}
registerReadmoreEventlisteners();
if (isLandingPage) {
  // registerSlider();
  (async() => {
    await initBlogArchive();
  })();
}
let isSearchInitialized = false;
const burgerMenuButton = document.getElementById("burgermenu-label");
if (burgerMenuButton) {
  burgerMenuButton.addEventListener("click", async() => {
    if (!isSearchInitialized) {
      await initSearch();
      isSearchInitialized = true;
    }
  });
}
initFlipcards();
// initLinks(); // not necessary anymore
window.contactInit = contactInit;

// window.addEventListener("load", function() {
//   contactInit();
// });

/* Scrolling behaviour of navigation */
const breadcrumb = document.getElementById("breadcrumb");
const triangle = document.getElementById(`triangle-burgermenu${isLandingPage ? "Landing" : ""}`);
const navLogo = document.getElementById("nav-company-logo");
const navLogoDiv = document.getElementById("nav-company-logo-divider");

const subheaderTitle = document.getElementById("subheader-title-text");

const subheader = document.getElementById("subheader");

/**
 * Check if the company logo and the subheadertitle overlap.
 */
const boundingBoxCheck = () => {
  // return only if there is no nav logo or subheader title
  if (!navLogo || !subheaderTitle || isLandingPage) {
    return false;
  }
  const { x: nX1, width: nW} = navLogo.getBoundingClientRect();
  const nX2 = nX1 + nW;
  const { x: sX1, width: sW} = subheaderTitle.getBoundingClientRect();
  const sX2 = sX1 + sW;

  const startMax = Math.max(nX1, sX1);
  const endLow = Math.min(nX2, sX2);
  return startMax < endLow;
  //return (nX1 >= sX1 && nX1 <= sX2) || (nX2 >= sX1 && nX2 <= sX2);
};

breadcrumb.style.top = "0";
triangle.style.top = "0";

export const setTopValuesToZero = () => {
  triangle.style.top = "0";
  breadcrumb.style.top = "0";
};
export const setTopValuesToMarginTop = () => {
  triangle.style.top = "200px";
  breadcrumb.style.top = "200px";
};
export const addHiddenToNavigation = () => {
  navLogo.classList.add("hidden");
  navLogoDiv.classList.add("hidden");
};
export const removeHiddenFromNavigation = () => {
  navLogo.classList.remove("hidden");
  navLogoDiv.classList.remove("hidden");
};
export const setBreadCrumbNavigation = () => {
  // const windowWidth = window.innerWidth;
  const currentScrollPos = window.scrollY;

  const hasCollision = boundingBoxCheck();

  const {top} = subheader.getBoundingClientRect();
  const subheaderPosition = window.scrollY + top;
  let stTop = 0;
  if (subheaderTitle) {
    stTop = subheaderTitle.getBoundingClientRect().top;
  }
  const titlePosition = window.scrollY + stTop;

  let threshold = Math.max((hasCollision && stTop !== 0) ? titlePosition - 100 : subheaderPosition - 100, 1);
  const scrollMaxY = window.scrollMaxY || (document.documentElement.scrollHeight - document.documentElement.clientHeight);

  if (threshold > scrollMaxY) {
    threshold -= scrollMaxY;
  }

  if (currentScrollPos >= threshold) {
    setTopValuesToMarginTop();
    removeHiddenFromNavigation();
  } else {
    setTopValuesToZero();
    if (isLandingPage) {
      addHiddenToNavigation();
    }
  }
};


window.addEventListener("scroll", setBreadCrumbNavigation);

const resetBlogArchive = async() => {
  unregisterBlogArchive();
  await initBlogArchive();
};

/* in order that the slider works after resizing */
window.addEventListener("resize", () => {
  setBreadCrumbNavigation();
  initLandingPageScroll();
  let debounce;
  if (isBlogPage && configuration.replaceBlogURLS)  {
    replaceBlogURLS();
  }
  if (isBlogPage || isLandingPage) {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(resetBlogArchive, 100);
  }
});

// detect multiple h1 tags per page
if (process.env.NODE_ENV === "development") {
  const headers = Array.from(document.querySelectorAll("h1"));
  if (headers.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(`detected ${headers.length} h1 tags on the page, it should only have one`);
    // eslint-disable-next-line no-console
    headers.forEach((h) => console.warn(h));
  }
  const links = Array.from(document.querySelectorAll("a"));
  links.forEach((link) => {
    if (link.hasAttribute("href")) {
      const ref = link.getAttribute("href");
      if (ref.startsWith("http") || ref.startsWith("tel:") || ref.toLowerCase().startsWith("mailto:") || ref.startsWith("#")) {
        return;
      }
      if (!ref.startsWith("/")) {
        // eslint-disable-next-line no-console
        console.warn(link, ref);
      }
    }
  });
}
